import React from 'react';

const HomePage = () => {
  return (
    <div className="space-y-6">
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">Ostatnio dodane</h2>
        <div className="text-gray-500">
          Brak paragonów. Wciśnij przycisk "Zeskanuj", aby dodać swój pierwszy paragon!
        </div>
      </div>
      
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">Statystyki</h2>
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-primary-50 p-4 rounded-lg">
            <p className="text-primary-900 font-medium">Wszystkie paragony</p>
            <p className="text-2xl font-bold text-primary-700">0</p>
          </div>
          <div className="bg-primary-50 p-4 rounded-lg">
            <p className="text-primary-900 font-medium">W tym miesiącu</p>
            <p className="text-2xl font-bold text-primary-700">0</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;