import React, { useEffect, useState } from 'react';

const PWAPrompt = () => {
  const [installPrompt, setInstallPrompt] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setInstallPrompt(e);
      setShowPrompt(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = async () => {
    if (!installPrompt) return;

    const result = await installPrompt.prompt();
    console.log(`Install prompt was: ${result.outcome}`);
    
    setInstallPrompt(null);
    setShowPrompt(false);
  };

  if (!showPrompt) return null;

  return (
    <div className="fixed bottom-20 left-4 right-4 bg-white rounded-lg shadow-lg p-4 border border-gray-200">
      <div className="flex flex-col space-y-2">
        <p className="text-gray-800 font-medium">
          Zainstaluj aplikację na swoim urządzeniu
        </p>
        <p className="text-sm text-gray-600">
          Zainstaluj naszą aplikację, aby łatwiej skanować i organizować paragony
        </p>
        <div className="flex space-x-2">
          <button
            onClick={() => setShowPrompt(false)}
            className="flex-1 px-4 py-2 text-gray-600 bg-gray-100 rounded-md hover:bg-gray-200"
          >
            Później
          </button>
          <button
            onClick={handleInstallClick}
            className="flex-1 px-4 py-2 text-white bg-primary-600 rounded-md hover:bg-primary-700"
          >
            Zainstaluj
          </button>
        </div>
      </div>
    </div>
  );
};

export default PWAPrompt;