import React from 'react';
import { Outlet } from 'react-router-dom';
import { Camera, Home, LogOut, User } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import PWAPrompt from '../shared/PWAPrompt';

const MainLayout = () => {
  const location = useLocation();

  const navigation = [
    { name: 'Strona główna', href: '/', icon: Home },
    { name: 'Zeskanuj', href: '/scan', icon: Camera },
    { name: 'Profil', href: '/profile', icon: User },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Top Navigation */}
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <div className="flex-shrink-0 flex items-center">
              <h1 className="text-xl font-bold text-primary-600">Skaner paragonów</h1>
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Outlet />
      </main>

      {/* Bottom Navigation */}
      <nav className="fixed bottom-0 w-full bg-white border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-around">
            {navigation.map((item) => {
              const IconComponent = item.icon;
              return (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`flex flex-col items-center py-4 px-2 text-sm font-medium ${
                    location.pathname === item.href
                      ? 'text-primary-600'
                      : 'text-gray-500 hover:text-gray-900'
                  }`}
                >
                  <IconComponent className="h-6 w-6" />
                  <span className="mt-1">{item.name}</span>
                </Link>
              );
            })}
          </div>
        </div>
      </nav>
      <PWAPrompt />
    </div>
  );
};

export default MainLayout;