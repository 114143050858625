import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAsAUIblIxiJbk5o-S05AUFEQyOAV-15kk",
  authDomain: "skanerparagonowapp.firebaseapp.com",
  projectId: "skanerparagonowapp",
  storageBucket: "skanerparagonowapp.firebasestorage.app",
  messagingSenderId: "32938343494",
  appId: "1:32938343494:web:7477b3879081b9d8a05e47",
  measurementId: "G-JZPJ56F6YK"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);